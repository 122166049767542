import React from 'react'
import { Link } from 'gatsby'

const CallToAction = ({primary}) => {
  //console.log('primary: ', primary);
  return (
    <div className="w-full py-12 sm:py-24 bg-primary">
      <div className="w-11/12 mx-auto max-w-screen-xl">
        <div className="flex-none sm:flex justify-around">
          <div className="w-full sm:w-6/12">
            <div className="prose prose-lg prose-h2:text-white font-verdana text-white leading-normal">
              <h2>{primary.heading1}</h2>
            </div>
            <div className="mt-12">
              <Link to="/contact/" className="font-verdana text-lg font-bold bg-white px-6 py-2 text-primary hover:text-primary">Make a booking</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction;