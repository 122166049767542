import React from 'react'

const Quote = ({items}) => {
  //console.log('items: ', items);
  return (
    <div className="w-full py-24 bg-grey">
      <div className="w-11/12 mx-auto max-w-screen-xl">
        <div className="flex-none sm:flex justify-between">
          {items.map((entry, i) => {
            return (
              <div key={`quote_${i}`} className="w-full pb-12 sm:pb-0 sm:w-6/12">
                <div className="sm:px-12 prose sm:prose-lg font-verdana text-black leading-none">
                  <h3>&quot;{entry.quote}&quot;</h3>
                  <p>- {entry.author}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Quote;